import getSlug from "speakingurl";

window.trackPageView = function(url) {
    if (typeof ga == 'function') {
        url = getSlug(url, {
            truncate: true,
            uric: true
        });

        ga('set', 'page', `${window.location.pathname}/${url}`);
        ga('send', 'pageview');
    }
};

window.trackEvent = function(category = null, action = null, label = null, value = null) {
    if (typeof ga == 'function') {
        ga('send', 'event', category, action, label, value);
    }
};